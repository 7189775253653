<template>
  <div class="control-list">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col md="3" sm="6">
              <b-form-group label="Дата, от">
                <b-input-group>
                  <b-form-input
                    v-model="filters.control_date__gte"
                    placeholder="ГГГГ-ММ-ДД"
                    type="text"
                  />
                  <b-input-group-append>
                    <b-datepicker
                      v-model="filters.control_date__gte"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      button-only
                      reset-button
                      right
                      size="sm"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="6">
              <b-form-group label="Дата, до">
                <b-input-group>
                  <b-form-input
                    v-model="filters.control_date__lte"
                    placeholder="ГГГГ-ММ-ДД"
                    type="text"
                  />
                  <b-input-group-append>
                    <b-datepicker
                      v-model="filters.control_date__lte"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      button-only
                      reset-button
                      right
                      size="sm"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-overlay :show="isLoading" rounded="sm">
                <b-table
                  :items="items"
                  :fields="fields"
                  empty-text="Нет данных"
                  hover
                  responsive
                  show-empty
                  @row-clicked="openDeal"
                />
              </b-overlay>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'ControlList',
  data() {
    return {
      items: [],
      fields: [
        { key: 'deal', label: 'ID сделки' },
        { key: 'doc_number', label: 'Номер договора' },
        { key: 'full_name', label: 'ФИО' },
        { key: 'message', label: 'Комментарий' },
        { key: 'control_date', label: 'Контрольная дата' },
        { key: 'manager_full_name', label: 'Менеджер' },
      ],
      isLoading: false,
      filters: {
        control_date__isnull: false,
        control_date__gte: null,
        control_date__lte: null,
      },
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getComments()
      },
    },
  },
  created() {
    this.getComments()
  },
  methods: {
    async getComments() {
      this.isLoading = true
      const res = await this.$http.get('admin/deal-comments/', {
        params: this.filters,
      })
      this.items = res.data.results
      this.isLoading = false
    },
    openDeal(row) {
      this.$router.push({ name: 'deal-view', params: { id: row.deal } })
    },
  },
}
</script>
